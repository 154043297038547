import React, {useContext} from "react";
import { GlobalDataContext } from '../../context/context';
import { ButtonContent } from "../global/boton/ButtonContent";

const Block_13 = () =>{

    const { rpdata } = useContext(GlobalDataContext);
    const pescado = 'https://firebasestorage.googleapis.com/v0/b/imagenessanti-2a052.appspot.com/o/pez-01.png?alt=media&token=5d0ece25-290b-4036-b0fd-b2be5443433b'

    return(
        <section className="w-full h-screen flex justify-between relative mb-20 lg:mb-0">
            <div className="w-[70%] h-full flex items-center">
                <div className="w-full lg:w-[70%] text-center relative">
                    <h5 className="font-normal mb-10 text-green-600">
                    <img 
                    src={pescado}
                    className='w-[60px] inline-block'
                    alt='pescadito'/>
                        {rpdata?.dbPrincipal?.name}
                        </h5>
                    <h2>{rpdata?.dbSlogan?.[0].slogan}</h2>
                    <span className="text-[20px] font-semibold">{rpdata?.dbSlogan?.[8].slogan}</span>
                    <div>
                    <ButtonContent/>
                    </div>
                </div>
            </div>
            <div className="w-[10%] lg:w-[30%] h-full bg1">
            </div>

            <img
                className="w-[250px] lg:w-[600px] absolute -bottom-20 right-14 lg:bottom-32 lg:right-[50px]"
                alt="gif-construccion"
                src="https://firebasestorage.googleapis.com/v0/b/imagenessanti-2a052.appspot.com/o/roof.gif?alt=media&token=c0b4af67-570e-4239-8376-17e246a2bd32"
             />
        </section>
    )      

}

export default Block_13