import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { FiTarget } from "react-icons/fi";
import { MdOutlineVisibility } from "react-icons/md";
import { FaHandshake } from "react-icons/fa";

function Values_2() {
  const { rpdata } = useContext(GlobalDataContext);

  const imgConstruccion =
    "https://firebasestorage.googleapis.com/v0/b/imagenessanti-2a052.appspot.com/o/construccion.png?alt=media&token=ab6cdf75-51a1-4cf1-b7de-86a951d8ae64";
  const pescado =
    "https://firebasestorage.googleapis.com/v0/b/imagenessanti-2a052.appspot.com/o/pez-01.png?alt=media&token=5d0ece25-290b-4036-b0fd-b2be5443433b";

  const valuesData = [
    {
      title: rpdata?.dbValues?.[0].title,
      description: rpdata?.dbValues?.[0].description,
      icon: (
        <FiTarget fontSize={70} className="bg-2 text-white rounded-full p-4" />
      ),
    },
    {
      title: rpdata?.dbValues?.[1].title,
      description: rpdata?.dbValues?.[1].description,
      icon: (
        <MdOutlineVisibility
          fontSize={70}
          className="bg-white color-2 rounded-full p-4 border-[2px] border-gray-500"
        />
      ),
    },
    {
      title: rpdata?.dbValues?.[2].title,
      description: rpdata?.dbValues?.[2].description,
      icon: (
        <FaHandshake
          fontSize={70}
          className="bg-2 text-white rounded-full p-4"
        />
      ),
    },
  ];

  return (
    <>
      <div className="md:w-2/3 w-full md:text-start text-center  md:mx-auto mt-6">
        <h5 className="text-green-600">
        <img
                  src={pescado}
                  className="w-[60px] inline-block"
                  alt="pescadito"
                />
          {rpdata?.dbPrincipal?.name}
          </h5>
        <h4 className="color-2">{rpdata?.dbSlogan?.[2].slogan}</h4>
      </div>
      <div className="md:w-4/5 md:mx-auto w-full flex flex-col lg:flex-row md:mb-0 mb-10">
        <div className="md:w-full lg:w-[60%]">
          <div className="bg-white w-4/5 mx-auto text-start flex md:flex-row flex-col my-6">
            <div className="flex flex-col justify-center items-center md:p-4 md:text-center text-center md:w-[30%]">
              {valuesData[0].icon}
              <span className="capitalize">{valuesData[0].title}</span>
            </div>
            <div className="p-4 border-l-[1px] border-gray-500 md:w-[70%]">
              <p className="md:text-start">
                {valuesData[0].description.substring(0, 2)}
                <img
                  src={pescado}
                  className="w-[40px] inline-block"
                  alt="pescadito"
                />
                <span className="text-green-600">
                  {valuesData[0].description.substring(2, 32)}
                </span>
                {valuesData[0].description.substring(32)}
              </p>
            </div>
          </div>

          <div className="bg-white w-4/5 mx-auto text-start flex md:flex-row flex-col my-6">
            <div className="flex flex-col justify-center items-center md:p-4 md:text-center text-center md:w-[30%]">
              {valuesData[1].icon}
              <span className="capitalize">{valuesData[1].title}</span>
            </div>
            <div className="p-4 border-l-[1px] border-gray-500 md:w-[70%]">
              <p className="md:text-start">{valuesData[1].description}</p>
            </div>
          </div>

          <div className="bg-white w-4/5 mx-auto text-start flex md:flex-row flex-col my-6">
            <div className="flex flex-col justify-center items-center md:p-4 md:text-center text-center md:w-[30%]">
              {valuesData[2].icon}
              <span className="capitalize">{valuesData[2].title}</span>
            </div>
            <div className="p-4 border-l-[1px] border-gray-500 md:w-[70%]">
              <p className="md:text-start">
                {valuesData[2].description.substring(0, 9)}
                <img
                  src={pescado}
                  className="w-[40px] inline-block"
                  alt="pescadito"
                />
                <span className="text-green-600">
                  {valuesData[2].description.substring(9, 39)}
                </span>
                {valuesData[2].description.substring(39)}
              </p>
            </div>
          </div>
        </div>
        <div className="lg:w-[40%] w-full md:h-auto h-[300px]">
          <div
            className="w-[98%] md:h-[500px] h-[350px] bg-contain bg-no-repeat bg-center m-1 rounded-md"
            style={{ backgroundImage: `url("${imgConstruccion}")` }}
          ></div>
        </div>
      </div>
    </>
  );
}

export default Values_2;
