import React, {useContext} from "react";
import { GlobalDataContext } from '../../context/context';
import imgDefault from "../../assets/image/placeholder.png"

const Block_12 = ({image,slogan,text}) => {

    const { rpdata } = useContext(GlobalDataContext);
    const pescado = 'https://firebasestorage.googleapis.com/v0/b/imagenessanti-2a052.appspot.com/o/pez-01.png?alt=media&token=5d0ece25-290b-4036-b0fd-b2be5443433b'

    return(
        <section className="w-full min-h-screen h-auto relative flex flex-col lg:flex-row ">
            <section className="w-full lg:w-[55%] h-auto relative bg-cover bg-center"
            style={{ backgroundImage: `url("${image ? image : imgDefault}")` }}
            > 
            <div className="absolute w-[180px] lg:w-[200px] z-10 -bottom-16 right-[25%] md:right-[35%] lg:bottom-[75%] lg:-right-24"
            >
                <img
                src={rpdata?.dbPrincipal?.logo}
                alt="photo_logo"
                className="w-full bg-white rounded-md"
                />
            </div>
                <div className="w-full h-full bg-[#00000077] text-white p-5  pb-20 flex items-center justify-center lg:justify-start text-center">
                    <h3>{slogan}</h3>
                </div>
            </section>
            <section className="w-full lg:w-[45%] h-auto py-20 bg2 flex items-center justify-end text-justify text-white">
                <div className="w-4/5 lg:p-10 mx-auto"> 
                    <p>
                        {text.substring(0,22)}
                        <img 
                                src={pescado}
                                className='w-[40px] inline-block'
                                alt='pescadito'/>
                                <span className='text-green-600'>
                                    {text.substring(22,52)}
                                </span>
                                {
                                    text.substring(52)
                                }
                    </p>
                </div>
            </section>
        </section>
    )

}

export default Block_12