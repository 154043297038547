import React, { useContext, useState } from "react";
import { GlobalDataContext } from "../../../context/context";
import MenuList from "../MenuList";
import { ButtonContent } from "../boton/ButtonContent";
import { GiHamburgerMenu } from "react-icons/gi";
import IconsRedes from "../IconRedes";

const Header_8 = () =>{
    const { rpdata } = useContext(GlobalDataContext);
    const [isOpen, setOpen] = useState(false);

    return(
        <header className="w-full h-[150px] flex justify-center relative">
            <section className="w-4/5 min-h-[120px] max-h-[150px] rounded-full shadow absolute -bottom-10 z-50 bg-white flex justify-between items-center px-10">
                <div className="w-4/5 md:w-[30%] lg:w-[20%] p-3 lg:p-5 lg:ml-10">
                    <img
                        className="w-full"
                        src={rpdata?.dbPrincipal?.logo}
                        alt="photo_logo_company"
                    />
                </div>
                <nav>
                   <div className="hidden lg:block">
                <MenuList classes={'text-black'}/>
                </div> 
                <div
            className='lg:hidden flex justify-center py-1 rounded-md w-[100px] h-[40px]'
            onClick={() => setOpen(true)}
          >
            <GiHamburgerMenu className="w-full h-full" />
          </div>
                </nav>
                <div className="hidden lg:block">
                <ButtonContent/>
                </div>
            </section>

            {isOpen ? (
        <div className="fixed z-50 top-0 right-0 left-0 bg-white w-full h-screen overflow-y-auto pb-10">
          <div className="flex justify-end w-[90%] mx-auto relative">
            <button
              className="font-bold text-[20px] mt-5"
              onClick={() => setOpen(false)}
            >
              X
            </button>
          </div>
          <div className="w-[60%] mx-auto pb-10">
            <img
              src={rpdata?.dbPrincipal?.logo}
              alt="logo"
              loading="lazy"
              className="w-full"
            />
          </div>
          <div className="w-4/5 mx-auto ">
            <MenuList classes="flex-col gap-3" />
          </div>

            <div className="flex flex-col items-center mt-28">
              <span className="font-bold">Follow Us On:</span>
            <IconsRedes classes="flex justify-center gap-5 text-black"/>
            </div>
        </div>
      ) : null}
        </header>
    )
}

export default Header_8