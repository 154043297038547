import React from "react";

const TransparentHeader = ({ headertitle, Subheader }) => {
  return (
    <div
      className="w-full h-[80vh] flex justify-between"
    >
      {/* <div className="text-center flex flex-col justify-end w-full h-full pb-[5%] relative">
        <h1 className="text-white">{headertitle}</h1>
        {
          headertitle === Subheader ?
            <h6 className="text-white">Home - {Subheader}</h6>
            :
            <h6 className="text-white">{Subheader}</h6>
        }
      </div> */}

      <div className="w-[80%] lg:w-[55%] bg-white flex items-center justify-center flex-col">
         <h1 className="text-black">{headertitle}</h1>
         {
          headertitle === Subheader ?
            <h6 className="text-black">Home - {Subheader}</h6>
            :
            <h6 className="text-black">{Subheader}</h6>
        }
         </div>
      <div className="w-[20%] lg:w-[45%] bg2"></div>
    </div>
  );
};

export default TransparentHeader;
